import React from "react";
import errorSymbol from "../../../img/error.svg";

class InputMessage extends React.Component {
  render() {
    return (
      <div className={`input-message input-message--error`}>
        <img src={errorSymbol} alt="Error" />
        <div className="input-message__content">{this.props.message}</div>
      </div>
    );
  }
}

export default InputMessage;
