import React from "react";
import SectionTitle from "../SectionTitle";
import BtnLightBlue from "../../btns/BtnLightBlue";
import problem from "../../../../img/mission/problem.svg";
import solution from "../../../../img/mission/solution.svg";

class Mission extends React.Component {
  render() {
    return (
      <section id="home-mission">
        <div className="container">
          <SectionTitle
            h2="Mission"
            h3="Helping people protect their privacy with actionable intelligence"
          />
          <ul className="mission-items">
            <li>
              <div className="mission-items__photo">
                <img src={problem} alt="Problem" />
              </div>
              <div className="mission-items__content">
                <h4>The Problem</h4>
                <p>
                The data broker industry is growing each day and that puts you at risk. Most people are unaware that their personal information and unique identifiers are all over the internet. In fact, over ninety-six websites that focus on data brokering have something personal about you listed. For as little as ten dollars, fraudsters can gain access to that information. But they aren’t finished there. Then they go on the darkweb with your name, address, and DOB, buying your SSN for as little as a dollar. Your identity is now compromised.
                </p>
              </div>
            </li>
            <li>
              <div className="mission-items__photo">
                <img src={solution} alt="Solution" />
              </div>
              <div className="mission-items__content">
                <h4>The Solution</h4>
                <p>
                Join PrivacyWave today for your online privacy and protection. We can guarantee that your name, current and former addresses, phone numbers, pictures of your home, as well as age won’t pop up on search engines any longer. 
                <br />
                <br />
                Together, we can send the cybercriminals packing.
                </p>
                <BtnLightBlue label="Get Started" to="/register" />
              </div>
            </li>
          </ul>
        </div>
      </section>
    );
  }
}

export default Mission;
