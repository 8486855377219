import React from "react";
import { CSSTransition } from "react-transition-group";
import TheaterVideo from "./TheaterVideo";
import { Parallax } from "react-parallax";
import YouTube from "react-youtube";
import headerBg from "../../../../img/header.jpg";

class Header extends React.Component {
  state = {
    play: false,
    layer1: false,
    layer2hide: false,
    video: false
  };

  onPlay = () => {
    this.setState(() => {
      return { play: true };
    });

    setTimeout(() => {
      this.setState(() => {
        return { layer1: true };
      });
    }, 1000);

    setTimeout(() => {
      this.setState(() => {
        return { video: true };
      });
    }, 2000);
  };

  ytReady = () => {
    this.setState(() => {
      return { layer2hide: true };
    });
  };

  hideVideo = () => {
    if (
      this.state.play &&
      this.state.layer1 &&
      this.state.layer2hide &&
      this.state.video
    )
      this.setState(() => {
        return { play: false, layer1: false, layer2hide: false, video: false };
      });
  };

  render() {
    const ytOpts = {
      height: "675",
      width: "1200",
      playerVars: {
        autoplay: 1
      }
    };

    return (
      <header id="site-header">
        <CSSTransition
          in={this.state.layer1}
          timeout={1000}
          classNames="focus-bg"
          unmountOnExit
        >
          <div className="focus-bg" onClick={this.hideVideo} />
        </CSSTransition>
        <div className="container">
          <div id="theater">
            {this.state.video && (
              <TheaterVideo>
                <YouTube
                  videoId="uDShf1p5730"
                  opts={ytOpts}
                  onReady={this.ytReady}
                  onEnd={this.hideVideo}
                />
              </TheaterVideo>
            )}
            {this.state.layer1 && (
              <div className={"theater-layer theater-layer-down "} />
            )}
            {this.state.video && (
              <div
                className={
                  "theater-layer theater-layer-up " +
                  (this.state.layer2hide ? "theater-layer-up--hide" : "")
                }
              >
                <div className="theater-layer-label">LOADING</div>
              </div>
            )}

            <Parallax
              blur={0}
              bgImage={headerBg}
              bgImageAlt="Privacy protection"
              strength={400}
            >
              <div className="theater-parallax-container">
                <h1 id="header-title">
                <b>Secure your Digital Life.</b>
                <p id="header-subtitle">Protect Yourself from Cyber bullying, stalking, harassment and crime.</p>
                </h1>
                {true && (
                  <div id="play-controls">
                    <div className="play-btn">
                      <div className="play-btn__pulse" />
                      <div
                        className={
                          "play-btn__bg " +
                          (this.state.play ? "play-btn__bg--full" : "")
                        }
                      />
                      <button className="play-btn__core" onClick={this.onPlay}>
                        <div className="play-btn__core__icon">
                          <i className="fas fa-play" />
                        </div>
                      </button>
                    </div>
                    <div className="play-label">About Video</div>
                  </div>
                )}
              </div>
            </Parallax>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
