import React from "react";
import logo from "../../../img/logo_footer.svg";
import NavItem from "../sections/nav/NavItem";

export default class Footer extends React.Component {
  render() {
    return (
      <footer id="site-footer">
        <div className="container">
          <div className="footer__logo">
            <img src={logo} alt="PrivacyWave" />
          </div>
          <ul className="footer__nav">
            <NavItem label="Mission" url="#mission" />
            <NavItem label="Reviews" url="#reviews" />
            <NavItem label="Education" url="#education" />
            <NavItem label="Instructor" url="#instructor" />
            <NavItem label="Blog" url="#blog" />
          </ul>
          <div className="footer_copyright">
            <span className="footer__address">
            1111 Mockingbird, Suite 475, Dallas, Texas 75247
            </span>
            Copyright PrivacyWave.com © 2019
          </div>
        </div>
      </footer>
    );
  }
}
