import React from "react";
import review_default from "../../../../img/reviews/review_default.png";
import BtnBlueStroke from "../../btns/BtnBlueStroke";

class ReviewSlide extends React.Component {
  render() {
    return (
      <div className="review">
        <img
          src={!!this.props.image ? this.props.image : review_default}
          alt={this.props.author}
        />
        <div className="review__content">
          <blockquote>{this.props.title} </blockquote>
          <p>{this.props.author}</p>
          {this.props.videoId && (
            <BtnBlueStroke
              label="Watch Review"
              onClick={() => {
                this.props.onClick(this.props.videoId);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ReviewSlide;
