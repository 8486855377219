import React from "react";
import { Link } from "react-router-dom";

const BtnWhiteStroke = props => {
  const { label, ...other } = props;

  if (props.to !== undefined)
    return (
      <Link className="btn btn--white-stroke" {...other}>
        {label}
      </Link>
    );

  if (props.href !== undefined)
    return (
      <a className="btn btn--white-stroke" {...other}>
        {label}
      </a>
    );

  return (
    <button className="btn btn--white-stroke" {...other}>
      {label}
    </button>
  );
};

export default BtnWhiteStroke;
