import React from "react";
import InputContainer from "../../util/InputContainer";
import BtnDarkBlue from "../../btns/BtnDarkBlue";

export default class ContactForm extends React.Component {
  state = {
    loading: false,
    submitted: false,
    formError: false,
    formMessage: "",
    option: "",
    firstName: "First name is required!",
    lastName: "Second name is required!",
    email: "Email is required!",
    phone: "Phone is required!",
    message: "Message is required!"
  };

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.validateField(name, value);
  };

  validateField(fieldName, value) {
    let valid = false;
    let message = "";

    switch (fieldName) {
      case "firstName":
        valid = value.length >= 2;
        if (!valid) message = "First name is not valid!";
        else message = "";
        break;
      case "lastName":
        valid = value.length >= 2;
        if (!valid) message = "Second name is not valid!";
        else message = "";
        break;
      case "email":
        valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if (!valid) message = "Email is not valid!";
        else message = "";
        break;
      case "phone":
        valid = value.length >= 2;
        if (!valid) message = "Phone is not valid!";
        else message = "";
        break;
      case "message":
        valid = value.length >= 5;
        if (!valid) message = "Message must be at least 5 characters long!";
        else message = "";
        break;
      default:
        break;
    }
    this.setState(prevState => {
      return {
        [fieldName]: message
      };
    });
  }

  onSubmit = e => {
    e.preventDefault();
    this.setState(prevState => {
      return { loading: true, submitted: true };
    });

    const validationFailed =
      !!this.state.firstName ||
      !!this.state.lastName ||
      !!this.state.email ||
      !!this.state.phone;

    if (validationFailed) {
      this.setState(prevState => {
        return { loading: false };
      });
      return;
    }

    const firstName = e.target.firstName.value;
    const lastName = e.target.lastName.value;
    const email = e.target.email.value;
    const phone = e.target.phone.value;
    const message = e.target.message.value;

    fetch(
      `https://privacywave.com/mail/contact.php?firstName=${firstName}&lastName=${lastName}&email=${email}&phone=${phone}&message=${message}&subject=${
        this.props.subject
      }`,
      {
        method: "GET"
      }
    )
      .then(res => {
        if (res.status === 200) {
          this.setState(() => {
            return {
              done: true,
              loading: false,
              formMessage: "Message Sent!"
            };
          });
        } else {
          this.setState(() => {
            return {
              formError: true,
              loading: false,
              formMessage: "There was an error trying to send a message."
            };
          });
        }
      })
      .catch(err => {
        this.setState(() => {
          return {
            formError: true,
            loading: false,
            formMessage: "There was an error (2) trying to send a message."
          };
        });
      });
  };

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <div className="cf-row cf-row--1">
          <div className="cf-row__cell">
            <input
              disabled
              type="text"
              name="subject"
              value={this.props.subject}
              className={"white-field"}
            />
          </div>
        </div>
        <div className="cf-row cf-row--2">
          <div className="cf-row__cell">
            <InputContainer
              message={this.state.submitted && this.state.firstName}
            >
              <input
                type="text"
                name="firstName"
                className={
                  "white-field " +
                  (this.state.firstName &&
                    this.state.submitted &&
                    "input-error")
                }
                placeholder="First name"
                onChange={this.handleUserInput}
                autoComplete="given-name"
              />
            </InputContainer>
          </div>
          <div className="cf-row__cell">
            <InputContainer
              message={this.state.submitted && this.state.lastName}
            >
              <input
                type="text"
                name="lastName"
                className={
                  "white-field " +
                  (this.state.lastName && this.state.submitted && "input-error")
                }
                placeholder="Last name"
                onChange={this.handleUserInput}
                autoComplete="family-name"
              />
            </InputContainer>
          </div>
        </div>
        <div className="cf-row cf-row--2">
          <div className="cf-row__cell">
            <InputContainer message={this.state.submitted && this.state.email}>
              <input
                type="text"
                name="email"
                className={
                  "white-field " +
                  (this.state.email && this.state.submitted && "input-error")
                }
                placeholder="Email"
                onChange={this.handleUserInput}
                autoComplete="email"
              />
            </InputContainer>
          </div>
          <div className="cf-row__cell">
            <InputContainer message={this.state.submitted && this.state.phone}>
              <input
                type="text"
                name="phone"
                className={
                  "white-field " +
                  (this.state.phone && this.state.submitted && "input-error")
                }
                placeholder="Phone"
                onChange={this.handleUserInput}
                autoComplete="phone"
              />
            </InputContainer>
          </div>
        </div>
        <div className="cf-row cf-row--1">
          <div className="cf-row__cell">
            <InputContainer
              message={this.state.submitted && this.state.message}
            >
              <textarea
                name="message"
                className={
                  "white-field " +
                  (this.state.message && this.state.submitted && "input-error")
                }
                placeholder="Your message..."
                onChange={this.handleUserInput}
              />
            </InputContainer>
          </div>
        </div>
        <div className="cf-submit">
          <BtnDarkBlue
            label="Send"
            loading={this.state.loading}
            submit={true}
          />
        </div>
        {this.state.formMessage && (
          <div
            className={
              `form-message ` +
              (this.state.formError
                ? `form-message--error`
                : `form-message--ok`)
            }
          >
            {this.state.formMessage}
          </div>
        )}
      </form>
    );
  }
}
