import React from "react";
import EducationItem from "./EducationItem";
import SectionTitle from "../SectionTitle";
import image1 from "../../../../img/education/1.png";
import image2 from "../../../../img/education/2.png";
import image3 from "../../../../img/education/3.png";
import image4 from "../../../../img/education/4.png";

class Education extends React.Component {
  render() {
    return (
      <section id="home-education">
        <div className="container">
          <SectionTitle h2="Education" line={false} dark={true} />
          <ul className="education-items">
            <EducationItem
              image={image1}
              title="PrivacyWave Mastermind"
              btnLabel="Buy Now"
              btnLink="#membership"
            >
              This course covers training for people who want to become experts in protecting their online privacy. 
              <br />
              <br />
              It consists of learning the fundamentals of your browser setting, email privacy, social media accounts, internet connection vulnerabilities, online shopping and ecommerce payments. And that’s just the start.
            </EducationItem>
            <EducationItem
              image={image2}
              title="Taking Your Privacy to the Next Level"
              btnLabel="Inquire"
              btnLink="#contact"
            >
              In today’s digital world, there are new threats emerging all the time. Control over your personal assets and privacy should not be something reserved for people with high net worth. PrivacyWave makes it possible for families with a median income to obtain the same levels of flexibility, control, and privacy as families who use private trust companies.
              <br />
              <br />
              Families of more modest wealth should not be left out.
            </EducationItem>
            <EducationItem
              image={image3}
              title="Live Conferences"
              btnLabel="Inquire"
              btnLink="#contact"
            >
              Learn more by enhancing your knowledge at a live conference. Our experts will share their deep technical knowledge, which will give you more tools to protect your privacy. Conferences and Mastermind training sessions are held quarterly in Dallas, Atlanta, Baltimore, and Newark, New Jersey.
            </EducationItem>
            <EducationItem
              image={image4}
              title="Executive Digital Protection"
              btnLabel="Inquire"
              btnLink="#contact"
            >
              It is more important now than ever to protect digital privacy. Our Executive Digital Protection offers a “private virtual bodyguard,” working to protect your privacy and reputation. We will provide an in-depth analysis of your security and privacy, as well a personalized and customizable plan. In addition, your dedicated private investigator will be available to give live support and address your concerns and issues.
            </EducationItem>
          </ul>
        </div>
      </section>
    );
  }
}

export default Education;
