import React from "react";
import PageLoader from "../common/util/PageLoader";
import { Helmet } from "react-helmet";

const API = "https://privacywave.com/wp/wp-json/wp/v2/posts?_embed&include[]=";

class PreRegisterPage extends React.Component {
  state = {
    loaded: false,
    data: undefined
  };

  componentDidMount() {
    console.log("ID JE:", this.props.match.params.id);
    fetch(API + this.props.match.params.id)
      .then(response => response.json())
      .then(data => this.setState({ loaded: true, data }));
  }

  removeHTMLTags = content => {
    const rex = /(<([^>]+)>)/gi;
    return content.replace(rex, "");
  };

  render() {
    if (!this.state.loaded) return <PageLoader />;

    const headerStyle = {
      backgroundImage:
        "url(" +
        this.state.data[0]._embedded["wp:featuredmedia"][0].media_details.sizes
          .full.source_url +
        ")",
      color: "green"
    };

    console.log(this.state.data[0].excerpt.rendered);

    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.data[0].title.rendered} | PrivacyWave</title>
          <meta
            name="description"
            content={this.removeHTMLTags(this.state.data[0].excerpt.rendered)}
          />
        </Helmet>
        <header id="post-header" style={headerStyle}>
          <div className="container">
            <h1>{this.state.data[0].title.rendered}</h1>
            <span className="post-author">
              By {this.state.data[0]._embedded.author[0].name}
            </span>
          </div>
          <div className="post-header__shade" />
        </header>
        <main id="post-main">
          <div className="container container--post">
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.data[0].content.rendered
              }}
            />
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default PreRegisterPage;
