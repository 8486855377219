import React from "react";

const BtnDarkBlue = props => {
  return (
    <button
      className="btn btn--dark-blue"
      disabled={props.loading}
      type={props.submit ? "submit" : "button"}
    >
      {props.loading ? "Sending..." : props.label}
    </button>
  );
};

export default BtnDarkBlue;
