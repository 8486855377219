import React from "react";
import GeneralPageContent from "../common/GeneralPageContent";
import InputContainer from "../common/util/InputContainer";
import BtnDarkBlue from "../common/btns/BtnDarkBlue";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

class PreRegisterPage extends React.Component {
  state = {
    loading: false,
    submitted: false,
    done: false,
    formError: false,
    formMessage: "",
    firstName: "First name is required!",
    lastName: "Second name is required!",
    email: "Email is required!",
    phone: "Phone is required!",
    serviceAgreement: "Service Agreement is required!"
  };

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.type === "checkbox") {
      this.validateField(name, e.target.checked);
    } else {
      this.validateField(name, value);
    }
  };

  validateField(fieldName, value) {
    let valid = false;
    let message = "";

    console.log(fieldName, value);

    switch (fieldName) {
      case "firstName":
        valid = value.length >= 2;
        if (!valid) message = "First name is not valid!";
        else message = "";
        break;
      case "lastName":
        valid = value.length >= 2;
        if (!valid) message = "Second name is not valid!";
        else message = "";
        break;
      case "email":
        valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if (!valid) message = "Email is not valid!";
        else message = "";
        break;
      case "phone":
        valid = value.length >= 2;
        if (!valid) message = "Phone is not valid!";
        else message = "";
        break;
      case "serviceAgreement":
        valid = value === true;
        if (!valid) message = "Service Agreement is required!";
        else message = "";
        break;
      default:
        break;
    }
    this.setState(prevState => {
      return {
        [fieldName]: message
      };
    });
  }

  onSubmit = e => {
    e.preventDefault();
    this.setState(prevState => {
      return { loading: true, submitted: true };
    });

    const validationFailed =
      !!this.state.firstName ||
      !!this.state.lastName ||
      !!this.state.email ||
      !!this.state.phone ||
      !!this.state.serviceAgreement;

    if (validationFailed) {
      this.setState(prevState => {
        return { loading: false };
      });
      return;
    }

    const firstName = e.target.firstName.value;
    const lastName = e.target.lastName.value;
    const email = e.target.email.value;
    const phone = e.target.phone.value;

    fetch(
      `https://privacywave.com/mail/preregister.php?firstName=${firstName}&lastName=${lastName}&email=${email}&phone=${phone}`,
      {
        method: "GET"
      }
    )
      .then(res => {
        if (res.status === 200) {
          this.setState(() => {
            return {
              done: true,
              loading: false,
              formMessage: "Registration Done!"
            };
          });
        } else {
          this.setState(() => {
            return {
              formError: true,
              loading: false,
              formMessage: "There was an error trying to register."
            };
          });
        }
      })
      .catch(err => {
        this.setState(() => {
          return {
            formError: true,
            loading: false,
            formMessage: "There was an error (2) trying to register."
          };
        });
      });
  };

  render() {
    const pStyle = {
      textAlign: "center",
      maxWidth: "60rem",
      margin: "0 auto"
    };

    return (
      <React.Fragment>
        <Helmet>
          <title>Register now | PrivacyWave</title>
          <meta
            name="description"
            content="Register now for PrivacyWave Course."
          />
          <link rel="canonical" href="https://privacywave.com/register" />
        </Helmet>
        <GeneralPageContent title="Register Now!">
          <p style={pStyle}>
            Register now and one of our privacy specialists will reach out to
            you and assist you to complete your registration and purchase of the
            course.
          </p>
          <div className="page-form page-form--center">
            {!this.state.done && (
              <form onSubmit={this.onSubmit}>
                <div className="cf-row cf-row--1">
                  <div className="cf-row__cell">
                    <InputContainer
                      message={this.state.submitted && this.state.firstName}
                    >
                      <input
                        type="text"
                        name="firstName"
                        className={
                          "white-field " +
                          (this.state.firstName &&
                            this.state.submitted &&
                            "input-error")
                        }
                        placeholder="First name"
                        onChange={this.handleUserInput}
                        autoComplete="given-name"
                      />
                    </InputContainer>
                  </div>
                </div>
                <div className="cf-row cf-row--1">
                  <div className="cf-row__cell">
                    <InputContainer
                      message={this.state.submitted && this.state.lastName}
                    >
                      <input
                        type="text"
                        name="lastName"
                        className={
                          "white-field " +
                          (this.state.lastName &&
                            this.state.submitted &&
                            "input-error")
                        }
                        placeholder="Last name"
                        onChange={this.handleUserInput}
                        autoComplete="family-name"
                      />
                    </InputContainer>
                  </div>
                </div>
                <div className="cf-row cf-row--1">
                  <div className="cf-row__cell">
                    <InputContainer
                      message={this.state.submitted && this.state.email}
                    >
                      <input
                        type="text"
                        name="email"
                        className={
                          "white-field " +
                          (this.state.email &&
                            this.state.submitted &&
                            "input-error")
                        }
                        placeholder="Email"
                        onChange={this.handleUserInput}
                        autoComplete="email"
                      />
                    </InputContainer>
                  </div>
                </div>
                <div className="cf-row cf-row--1">
                  <div className="cf-row__cell">
                    <InputContainer
                      message={this.state.submitted && this.state.phone}
                    >
                      <input
                        type="text"
                        name="phone"
                        className={
                          "white-field " +
                          (this.state.phone &&
                            this.state.submitted &&
                            "input-error")
                        }
                        placeholder="Phone"
                        onChange={this.handleUserInput}
                        autoComplete="phone"
                      />
                    </InputContainer>
                  </div>
                </div>
                <div className="cf-row cf-row--1">
                  <div className="cf-row__cell cf-row__cell--cb">
                    <InputContainer
                      message={
                        this.state.submitted && this.state.serviceAgreement
                      }
                    >
                      <label>
                        <input
                          type="checkbox"
                          name="serviceAgreement"
                          onChange={this.handleUserInput}
                        />
                        <span className="cb-label">
                          I agree to the{" "}
                          <Link to="/service-agreement">Service Agreement</Link>
                        </span>
                      </label>
                    </InputContainer>
                  </div>
                </div>
                <div className="cf-submit">
                  <BtnDarkBlue
                    label="Send"
                    loading={this.state.loading}
                    submit={true}
                  />
                </div>
              </form>
            )}
            {this.state.formMessage && (
              <div
                className={
                  `form-message ` +
                  (this.state.formError
                    ? `form-message--error`
                    : `form-message--ok`)
                }
              >
                {this.state.formMessage}
              </div>
            )}
          </div>
        </GeneralPageContent>
      </React.Fragment>
    );
  }
}

export default PreRegisterPage;
