import React from "react";
import GeneralPageContent from "../common/GeneralPageContent";
import SectionTitle from "../common/sections/SectionTitle";
import FaqItem from "../common/sections/FaqItem";
import PageLoader from "../common/util/PageLoader";
import books from "../../img/books.jpg";
import NewsletterForm from "../common/newsletter/NewsletterForm";
import { Helmet } from "react-helmet";

const API_COURSE_DETAILS =
  "https://privacywave.com/wp/wp-json/wp/v2/coursedetails";
const API_FAQS = "https://privacywave.com/wp/wp-json/wp/v2/faq";

class PreRegisterPage extends React.Component {
  state = {
    loadedFaq: false,
    loadedCourseDetails: false,
    submitted: false,
    formError: false,
    formMessage: "",
    email: "Email is required!",
    courseDetails: [],
    faqs: []
  };

  componentDidMount() {
    fetch(API_COURSE_DETAILS)
      .then(response => response.json())
      .then(data =>
        this.setState({ loadedCourseDetails: true, courseDetails: data })
      );

    fetch(API_FAQS)
      .then(response => response.json())
      .then(data => this.setState({ loadedFaq: true, faqs: data }));
  }

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.validateField(name, value);
  };

  validateField(fieldName, value) {
    let valid = false;
    let message = "";

    switch (fieldName) {
      case "email":
        valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if (!valid) message = "Email is not valid!";
        else message = "";
        break;
      default:
        break;
    }
    this.setState(prevState => {
      return {
        [fieldName]: message
      };
    });
  }

  onSubmit = e => {
    e.preventDefault();
    this.setState(prevState => {
      return { loading: true, submitted: true };
    });
  };

  render() {
    if (!(this.state.loadedFaq && this.state.loadedCourseDetails))
      return <PageLoader />;

    return (
      <React.Fragment>
        <Helmet>
          <title>Course Details | PrivacyWave</title>
          <meta name="description" content="PrivacyWave Course details." />
          <link rel="canonical" href="https://privacywave.com/course-details" />
        </Helmet>
        <GeneralPageContent title="Course Details">
          {/* <p>
           PrivacyWave Mastermind includes 16 video lessons featuring Ebenezer
            Allen. Follow along in your workbook as he teaches you:
          </p>
          <ul className="list-huge">
            {this.state.courseDetails.map(detail => {
              return <li className="course-detail">{detail.title.rendered}</li>;
            })}
          </ul> */}
          <SectionTitle h2="FAQ" h3="Frequently Asked Questions" line={false} />
          <ul className="list-faq">
            {this.state.faqs.map(lesson => {
              return (
                <FaqItem
                  question={lesson.title.rendered}
                  answer={lesson.acf.answer}
                />
              );
            })}
          </ul>
        </GeneralPageContent>
        <section className="book-newsletter">
          <div className="container">
            <div className="book-left">
              <img src={books} alt="PrivacyWave Book" />
            </div>
            <div className="book-right">
              <h4>Book Alert</h4>
              <h3>
                Privacy Wave: a guide to combating cybercrime &amp; criminals
              </h3>
              <span className="book-price">$14.99</span>
              <span className="book-release">
                Release Date: October 12th, 2019
              </span>
              <NewsletterForm />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default PreRegisterPage;
