import React from "react";
import SectionTitle from "../SectionTitle";
import BlogListItem from "./BlogListItem.js";
import BtnBlueStroke from "../../btns/BtnBlueStroke";

const API = "https://privacywave.com/wp/wp-json/wp/v2/posts?_embed";

export default class Blog extends React.Component {
  state = {
    postPerPage: 2,
    data: []
  };

  componentDidMount() {
    this.loadPosts();
  }

  loadPosts = () => {
    fetch(API)
      .then(response => response.json())
      .then(data =>
        this.setState({
          data: data
        })
      );
  };

  handleLoadMore = () => {
    this.setState(prevState => {
      return { postPerPage: prevState.postPerPage + 2 };
    });
  };

  render() {
    let i = 0;
    return (
      <section id="home-blog">
        <div className="container">
          <SectionTitle h2="Blog" h3="Latest Articles" line={false} />
          <ul className="blog-items">
            {this.state.data.map(post => {
              const featuredMedia = post._embedded["wp:featuredmedia"];
              ++i;
              if (i > this.state.postPerPage) return;
              return (
                <BlogListItem
                  key={post.id}
                  title={post.title.rendered}
                  author={post._embedded.author[0].name}
                  url={`/blog/${post.id}/${post.slug}`}
                  image={
                    featuredMedia === undefined
                      ? ""
                      : featuredMedia["0"].media_details.sizes.blog_thumbnail
                          .source_url
                  }
                />
              );
            })}
          </ul>
          {this.state.postPerPage < this.state.data.length && (
            <div className="blog-more">
              <BtnBlueStroke label="Load more" onClick={this.handleLoadMore} />
            </div>
          )}
        </div>
      </section>
    );
  }
}
