import React from "react";

export default class GeneralPageContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <header id="page-header">
          <div className="container">
            <h1>{this.props.title}</h1>
          </div>
        </header>
        <main id="page-main">
          <div className="container">{this.props.children}</div>
        </main>
      </React.Fragment>
    );
  }
}
