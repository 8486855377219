import React from "react";
import SectionTitle from "../SectionTitle";
import ReviewSlide from "./ReviewSlide";
import { CSSTransition } from "react-transition-group";
import Swiper from "react-id-swiper";
import { Navigation, EffectFade } from "swiper/dist/js/swiper.esm";
import YouTube from "react-youtube";

const API = "https://privacywave.com/wp/wp-json/wp/v2/reviews";

class Reviews extends React.Component {
  state = {
    playing: false,
    loaded: false,
    videoId: "",
    data: []
  };

  componentDidMount() {
    fetch(API)
      .then(response => response.json())
      .then(data => this.setState({ data }));
  }

  playVideo = videoId => {
    this.setState(prevState => {
      return { playing: true, videoId };
    });
  };

  hideVideo = () => {
    this.setState(prevState => {
      return { playing: false };
    });
  };

  render() {
    const ytOpts = {
      height: "100%",
      width: "100%",
      playerVars: {
        autoplay: 1
      }
    };

    const params = {
      modules: [Navigation, EffectFade],
      shouldSwiperUpdate: true,
      observer: true,
      loop: true,
      noSwiping: true,
      effect: "fade",
      navigation: {
        nextEl: "#review-arrow-right",
        prevEl: "#review-arrow-left"
      }
    };

    return (
      <section id="home-reviews">
        <CSSTransition
          in={this.state.playing}
          timeout={1000}
          classNames="focus-bg"
          unmountOnExit
        >
          <div className="focus-bg" onClick={this.hideVideo} />
        </CSSTransition>

        <CSSTransition
          in={this.state.playing}
          timeout={300}
          classNames="review__popup"
          unmountOnExit
        >
          <div className="review__popup">
            <YouTube
              videoId={this.state.videoId}
              opts={ytOpts}
              onEnd={this.hideVideo}
            />
          </div>
        </CSSTransition>

        <div className="container">
          <SectionTitle h2="Reviews" line={false} />
          <div className="reviews-slider">
            <div className="reviews-slider__arrow">
              <button id="review-arrow-left" />
            </div>
            <div className="reviews-slider__center">
              <Swiper {...params}>
                {this.state.data.map(review => {
                  return (
                    <div key={review.id}>
                      <ReviewSlide
                        key={review.id}
                        title={review.title.rendered}
                        author={review.acf.author}
                        image={review.acf.image.sizes.review}
                        onClick={this.playVideo}
                        videoId={review.acf.video_id}
                      />
                    </div>
                  );
                })}
              </Swiper>
            </div>
            <div className="reviews-slider__arrow">
              <button id="review-arrow-right" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Reviews;
