import React from "react";
import { Link } from "react-router-dom";

export default class BlogListItem extends React.Component {
  render() {
    return (
      <li>
        <Link to={this.props.url}>
          <div className="blog-items__image">
            <div className="blog-items__image__hover" />
            {this.props.image && (
              <img src={this.props.image} alt={this.props.title} />
            )}
          </div>
          <div className="blog-items__content">
            <h4>{this.props.title}</h4>
            <p>BY {this.props.author}</p>
          </div>
        </Link>
      </li>
    );
  }
}
