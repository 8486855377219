import React from "react";
import SectionTitle from "../SectionTitle";
import AnimateHeight from "react-animate-height";
import ContactOption from "./ContactOption";
import ContactForm from "./ContactForm";
import inquiry from "../../../../img/contact/general.svg";
import microphone from "../../../../img/contact/speaking.svg";
import press from "../../../../img/contact/press.svg";
import support from "../../../../img/contact/support.svg";

export default class Contact extends React.Component {
  state = {
    subject: ""
  };

  render() {
    return (
      <section id="home-contact">
        <div className="container">
          <SectionTitle h2="Reach out" h3="Contact Us" line={false} />
          <div className="contact-intro">
            <p>
            Get in touch with Ben Allen or the PrivacyWave team.
            </p>
          </div>
          <ul className="contact-options">
            <ContactOption
              icon={inquiry}
              label="General inquiry"
              text="Click here to submit your question or inquiry about anything."
              onPick={e => {
                this.setState({ subject: "General inquiry" });
              }}
            />
            <ContactOption
              icon={microphone}
              label="Hire to speak"
              text="If you’re looking to have Ben speak at your conference or host a company training, this is the way to do it."
              onPick={e => {
                this.setState({ subject: "Hire to speak" });
              }}
            />
            <ContactOption
              icon={press}
              label="Press and media"
              text="For all interviews, media opportunities, and anything else press-related."
              onPick={e => {
                this.setState({ subject: "Press and media" });
              }}
            />
            <ContactOption
              icon={support}
              label="Customer support"
              text="Submit any support questions you may have while you are currently a customer of a Privacywave.com Program."
              onPick={e => {
                this.setState({ subject: "Customer support" });
              }}
            />
          </ul>

          <AnimateHeight
            duration={500}
            height={this.state.subject ? "auto" : 0}
          >
            <div className="contact-form">
              <ContactForm subject={this.state.subject} />
            </div>
          </AnimateHeight>
        </div>
      </section>
    );
  }
}
