import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Nav from "../components/common/sections/nav/Nav";
import Footer from "../components/common/sections/Footer";
import HomePage from "../components/pages/HomePage";
import PreRegisterPage from "../components/pages/PreRegisterPage";
import ServiceAgreementPage from "../components/pages/ServiceAgreementPage";
import CourseDetailsPage from "../components/pages/CourseDetailsPage";
import BlogPostPage from "../components/pages/BlogPostPage";

export default class AppRouter extends React.Component {
  render() {
    return (
      <Router>
        <Nav />
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/register" component={PreRegisterPage} />
            <Route path="/service-agreement" component={ServiceAgreementPage} />
            <Route path="/course-details" component={CourseDetailsPage} />
            <Route path="/blog/:id" component={BlogPostPage} />
            <Route component={HomePage} />
          </Switch>
        </ScrollToTop>
        <Footer />
      </Router>
    );
  }
}
