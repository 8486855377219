import React from "react";
import GeneralPageContent from "../common/GeneralPageContent";

class PreRegisterPage extends React.Component {
  render() {
    return (
      <GeneralPageContent title="Service Agreement">
        <p>
          Thank you for choosing PrivacyWave. This Service Agreement explains
          the conditions upon which you may use our training and services. By
          choosing to use PrivacyWave, you are agreeing to the conditions of
          this Service Agreement. You are not to reproduce or distribute any
          PrivacyWave content without prior written permission, and if you do so
          you may be subject to fines or any other penalties allowed by the
          civil and criminal laws of the relevant jurisdiction. PrivacyWave
          Mastermind Training is intended for educational purposes, and the
          program requires personal action and responsibility. At no time, will
          PrivacyWave or its executives, employees or partners be responsible in
          any way for any loss caused, or alleged to have been caused by our
          products or services.
        </p>
      </GeneralPageContent>
    );
  }
}

export default PreRegisterPage;
