import React from "react";
import { CSSTransition } from "react-transition-group";
import InputMessage from "./InputMessage";

class InputContainer extends React.Component {
  render() {
    return (
      <div className="input-container">
        {this.props.children}

        <CSSTransition
          in={!!this.props.message}
          timeout={200}
          classNames="input-message"
          unmountOnExit
        >
          <InputMessage message={this.props.message} />
        </CSSTransition>
      </div>
    );
  }
}

export default InputContainer;
