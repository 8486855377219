import React from "react";
import logo from "../../../../img/logo.svg";
import BtnWhiteStroke from "../../btns/BtnWhiteStroke";
import NavItem from "./NavItem";
import { Link } from "react-router-dom";

const SCROLL_OFFSET = 200;
const SCROLL_OFFSET_L = 30;
let lastScrollTop = 0;

const desktopLineMarginLeft = 30;
const mobileLineMarginLeft = 0;

const BREAKPOINT = 105;

class Nav extends React.Component {
  state = {
    scroll: false,
    up: true,
    visible: false,
    scrollByClick: false,
    sectionNo: 0,
    lineWidth: 0
  };

  componentDidMount() {
    const viewWidth = window.innerWidth;

    this.setState(() => ({
      lineWidth: 0,
      lineLeft:
        viewWidth >= BREAKPOINT
          ? desktopLineMarginLeft + "px"
          : mobileLineMarginLeft + "px"
    }));

    window.addEventListener("scroll", () => {
      setTimeout(() => {
        this.hashChange();
      }, 200);
    });

    window.addEventListener("scroll", this.handleScroll);
  }

  hashChange = () => {
    const hash = window.location.hash;
    let sectionNo;
    switch (hash) {
      case "#mission":
        sectionNo = 1;
        break;
      case "#reviews":
        sectionNo = 2;
        break;
      case "#education":
        sectionNo = 3;
        break;
      case "#instructor":
        sectionNo = 4;
        break;
      case "#blog":
        sectionNo = 5;
        break;
      default:
        sectionNo = 0;
    }
    this.handleHover(sectionNo);
  };

  handleHover = newSectionNo => {
    const viewWidth = window.innerWidth;
    this.setState(() => ({ sectionNo: newSectionNo }));
    const navItems = document.querySelectorAll(".site-nav__list ul li a");
    let lineLeft =
      viewWidth >= BREAKPOINT ? desktopLineMarginLeft : mobileLineMarginLeft;

    /**
     * If it's the top of the page without nav item
     */
    if (newSectionNo === 0) {
      this.setState(() => ({
        lineWidth: 0
      }));
      return;
    }

    for (let i = 0; i < newSectionNo - 1; i++) {
      lineLeft += desktopLineMarginLeft + navItems[i].offsetWidth;
    }

    this.setState(() => ({
      lineWidth: navItems[newSectionNo - 1].offsetWidth,
      lineLeft: lineLeft + "px"
    }));
  };

  handleScroll = () => {
    const viewWidth = window.innerWidth;

    /**
     * Determine if we're scrolling up or down
     * Special case: if scrolling is caused by nav links, ignore scrolling
     */
    if (this.state.scrollByClick === false) {
      let currentScrollTip =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollTip > lastScrollTop) {
        this.setState(() => {
          return {
            up: false
          };
        });
      } else {
        this.setState(() => {
          return {
            up: true
          };
        });
      }
      lastScrollTop = currentScrollTip <= 0 ? 0 : currentScrollTip;
    }

    /**
     * Check if scrolling is big enough to minimize nav (with logo)
     */
    if (window.scrollY > SCROLL_OFFSET && viewWidth > 1300) {
      this.setState(() => {
        return {
          scroll: true
        };
      });
    } else if (window.scrollY > SCROLL_OFFSET_L) {
      this.setState(() => {
        return {
          scroll: true
        };
      });
    } else {
      this.setState(() => {
        return {
          scroll: false
        };
      });
    }
  };

  hideNav = () => {
    this.setState(prevState => {
      return { scrollByClick: true, up: false };
    });

    setTimeout(() => {
      this.setState(prevState => {
        return { scrollByClick: false, up: false };
      });
    }, 800);
  };

  toggleUl = () => {
    this.setState(prevState => {
      return { visible: !prevState.visible };
    });

    /**
     * toggleUI function is called on 1. nav toggle 2. nav item click
     * we want to hide whole nav (not just ul) to leave more screen space for the content
     */
    this.hideNav();
  };

  render() {
    const lineStyle = {
      width: this.state.lineWidth,
      left: this.state.lineLeft
    };

    return (
      <React.Fragment>
        <section id="site-top">
          <div className="container">
            <div className="site-top__social">
              <a
                href="https://www.linkedin.com/in/ebenezerallen/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin" />
              </a>
              <a
                href="https://www.facebook.com/ebenezerallencfe/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f" />
              </a>
              <a
                href="https://twitter.com/ebenezerallen"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter" />
              </a>
            </div>
            <div className="site-top__phone">Tel: +1 903 818 1409</div>
          </div>
        </section>
        <nav
          id="site-nav"
          className={
            (this.state.scroll ? "site-nav--scroll " : "") +
            (this.state.up ? "site-nav--up " : "site-nav--down ") +
            (this.state.visible ? "site-nav--visible " : "")
          }
        >
          <div className="container">
            <Link to="/" className="site-nav-home-link">
              <img
                className="site-nav__logo"
                src={logo}
                alt="PrivacyWave logo"
              />
            </Link>
            <div className="site-nav__list">
              <ul>
                <NavItem
                  label="Mission"
                  url="#mission"
                  onMouseEnter={() => {
                    this.handleHover(1);
                  }}
                  onClick={this.toggleUl}
                />
                <NavItem
                  label="Reviews"
                  url="#reviews"
                  onMouseEnter={() => {
                    this.handleHover(2);
                  }}
                />
                <NavItem
                  label="Education"
                  url="#education"
                  onMouseEnter={() => {
                    this.handleHover(3);
                  }}
                  onClick={this.toggleUl}
                />
                <NavItem
                  label="Instructor"
                  url="#instructor"
                  onMouseEnter={() => {
                    this.handleHover(4);
                  }}
                  onClick={this.toggleUl}
                />
                <NavItem
                  label="Blog"
                  url="#blog"
                  onMouseEnter={() => {
                    this.handleHover(5);
                  }}
                  onClick={this.toggleUl}
                />
                <div className="nav-line" style={lineStyle} />
              </ul>
            </div>
            <div className="site-nav__btn">
              <BtnWhiteStroke label="Get Started" to="/register" />
              <button
                className={`hamburger hamburger--collapse ${
                  this.state.visible ? "is-active" : ""
                }`}
                type="button"
                onClick={e => {
                  this.toggleUl();
                }}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default Nav;
