import React from "react";
import { CSSTransition } from "react-transition-group";
import BtnWhiteStroke from "../../btns/BtnWhiteStroke";
import MembershipItems from "./MembershipItems";
import MembershipPrice from "./MembershipPrice";
import MembershipHeader from "./MembershipHeader";
// import { Link } from "react-router-dom";

export default class Membership extends React.Component {
  state = {
    slide: 1
  };

  handleSlide = slideNo => {
    if (slideNo === this.state.slide) return;

    this.setState(() => {
      return { slide: 0 };
    });

    setTimeout(() => {
      this.setState(() => {
        return { slide: slideNo };
      });
    }, 150);
  };

  render() {
    return (
      <section id="home-membership">
        <div className="membership-bg" />
        <div className="container">
          <div className="membership-left">
            <h2>Membership</h2>
            <h3>Join the PrivacyWave</h3>
            <div className="membership-left__bottom">
              <div className="membership-price-container">
                <CSSTransition
                  in={this.state.slide === 1}
                  timeout={200}
                  classNames="membership-price"
                  unmountOnExit
                >
                  <MembershipPrice price="$1,227.00" />
                </CSSTransition>
                <CSSTransition
                  in={this.state.slide === 2}
                  timeout={200}
                  classNames="membership-price"
                  unmountOnExit
                >
                  <MembershipPrice price="$297.00" />
                </CSSTransition>
                <CSSTransition
                  in={this.state.slide === 3}
                  timeout={200}
                  classNames="membership-price"
                  unmountOnExit
                >
                  <MembershipPrice price="$97.00" />
                </CSSTransition>
                <span>/year</span>
              </div>
              <BtnWhiteStroke label="Get Started" href="/register" />
              {/* <div className="membership-more">
                or <Link to="/course-details">Find out more</Link>
              </div> */}
            </div>
          </div>
          <div className="membership-right">
            <MembershipHeader
              totalSlides={3}
              slide={this.props.slide}
              onClick={this.handleSlide}
            />
            <MembershipItems slide={this.state.slide} />
          </div>
        </div>
      </section>
    );
  }
}
