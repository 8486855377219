import React from "react";
import AnimateHeight from "react-animate-height";

export default class FaqItem extends React.Component {
  state = {
    opened: false
  };

  render() {
    return (
      <li className={`faq-item ${this.state.opened ? "faq-item--opened" : ""}`}>
        <div
          className="faq__header"
          onClick={() => {
            this.setState(prevState => ({ opened: !prevState.opened }));
          }}
        >
          <h5>{this.props.question}</h5>
          <div className="faq__header__arrow" />
        </div>

        <AnimateHeight duration={250} height={this.state.opened ? "auto" : "0"}>
          <div className="faq__content">
            <p>{this.props.answer}</p>
          </div>
        </AnimateHeight>
      </li>
    );
  }
}
