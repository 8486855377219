import React from "react";

class SectionTitle extends React.Component {
  render() {
    return (
      <div className="section-title">
        <h2>{this.props.h2}</h2>
        <h3>{this.props.h3}</h3>
        {this.props.line ? <div className="section-title__line" /> : ""}
      </div>
    );
  }
}

export default SectionTitle;
