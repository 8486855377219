import React from "react";

export default class PageLoader extends React.Component {
  render() {
    return (
      <section id="site-loader">
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      </section>
    );
  }
}
