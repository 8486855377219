import React from "react";
import { Link } from "react-router-dom";

const BtnLightBlue = props => {
  const { label, ...other } = props;

  if (props.to !== undefined)
    return (
      <Link className="btn btn--light-blue" {...other}>
        {label}
      </Link>
    );

  if (props.href !== undefined)
    return (
      <a className="btn btn--light-blue" {...other}>
        {label}
      </a>
    );

  return <button className="btn btn--light-blue">{label}</button>;
};

export default BtnLightBlue;
