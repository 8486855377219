import React from "react";
import instructor from "../../../../img/instructor/instructor.png";

export default class Instructor extends React.Component {
  render() {
    return (
      <section id="home-instructor">
        <div className="container">
          <div className="instructor-left">
            <div className="instructor-left__photo">
              <img src={instructor} alt="Ben Allen" />
            </div>
            <div className="instructor-left__social">
              <a
                href="https://www.linkedin.com/in/ebenezerallen/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin" />
              </a>
              <a
                href="https://www.facebook.com/ebenezerallencfe/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f" />
              </a>
              <a
                href="https://twitter.com/ebenezerallen"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter" />
              </a>
            </div>
          </div>
          <div className="instructor-spacing" />
          <div className="instructor-right">
            <div className="instructor-right__content">
              <h5>Instructor</h5>
              <h4>Ben Allen</h4>
              <p>
              Ben Allen is the CEO and founder of PrivacyWave.com. 
              He received his Master’s in Business Administration from Lincoln 
              University in Oakland, California. He then went on to become a 
              certified fraud examiner and licensed private investigator. He has 
              Fortune 500 service experience, working for major corporations like 
              Capital One Financial, Standard Chartered Bank, MoneyGram and others.
              <br />
              <br />
              His primary expertise is in: Online privacy, Fraud investigation, 
              Identity theft, Open source intelligence and Account take over 
              remediation.
              <br />
              <br />
              Having worked over 1,000 cases, he continues to help 
              people protect their privacy with actionable intelligence. 
              <br />
              <br />
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
