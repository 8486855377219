import React from "react";
import BtnLightBlueStroke from "../../btns/BtnLightBlueStroke";

class EducationItem extends React.Component {
  render() {
    const { title, children, image, btnLabel } = this.props;
    return (
      <li>
        <div className="education__content">
          <h4>{title}</h4>
          <div className="education-line" />
          <p>{children}</p>
          {this.props.btnLink !== undefined ? (
            <BtnLightBlueStroke label={btnLabel} href={this.props.btnLink} />
          ) : (
            <BtnLightBlueStroke label={btnLabel} />
          )}
        </div>
        <img src={image} alt={title} />
      </li>
    );
  }
}

export default EducationItem;
