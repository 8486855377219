import React from "react";
import { CSSTransition } from "react-transition-group";

export default class MembershipItems extends React.Component {
  render() {
    return (
      <ul className="membership-items">
        <CSSTransition
          in={this.props.slide === 1}
          timeout={200}
          classNames="membership-content"
          unmountOnExit
        >
          <li className="membership-content">
            <p>PrivacyWave Platinum Membership includes:</p>
            <ul>
              <li>
                Erase your personal information from 96 data broker websites
              </li>
              <li>Continuous monitoring</li>
              {/* <span>Coming soon</span> */}
              <li>PrivacyWave Mastermind Workbook</li>
              <li>
                Access to Ben Allen via voice calls, video calls and direct
                email
              </li>
              <li>Monthly newsletters</li>
              <li>Ability to refer and earn</li>
              <li>Removing you from commercial mail lists</li>
              <li>Stop telemarketers from calling </li>
            </ul>
          </li>
        </CSSTransition>
        {/* <CSSTransition
          in={this.props.slide === 2}
          timeout={200}
          classNames="membership-content"
          unmountOnExit
        >
          <li className="membership-content">
            <p>PrivacyWave Gold Membership includes:</p>
            <ul>
              <li>
                Erase your personal information from 96 data broker websites
              </li>
              <li>PrivacyWave Mastermind Workbook</li>
              <li>Membership Card</li>
              <li>Continuous monitoring</li>
              <li>
                An online community made up of like-minded people who are working
                on protecting their privacy
              </li>
            </ul>
          </li>
        </CSSTransition>
        <CSSTransition
          in={this.props.slide === 3}
          timeout={200}
          classNames="membership-content"
          unmountOnExit
        >
          <li className="membership-content">
            <p>PrivacyWave Gold Membership includes:</p>
            <ul>
              <li>
                Erase your personal information from 96 data broker websites
              </li>
              <li>PrivacyWave Mastermind Workbook</li>
            </ul>
          </li>
        </CSSTransition> */}
      </ul>
    );
  }
}
