import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { ReactComponent as Loader } from "../../../img/newsletter_loader.svg";

class CustomForm extends React.Component {
  submit(e) {
    e.preventDefault();
    const email = e.target.email.value;
    this.props.onValidated({
      EMAIL: email
    });
  }

  render() {
    return (
      <div className="newsletter-form-wrapper">
        {this.props.status !== "success" && (
          <form onSubmit={this.submit.bind(this)} className="newsletter-form">
            <input
              type="email"
              name="email"
              className="newsletter-form__email"
              placeholder="Your email"
            />
            <button className="newsletter-form__button btn btn--dark-blue">
              Subscribe
            </button>
          </form>
        )}

        {this.props.status === "sending" && (
          <div className="newsletter-form__loading">
            <Loader />
          </div>
        )}
        {this.props.status === "error" && (
          <div
            className="newsletter-form__message newsletter-form__message--error"
            dangerouslySetInnerHTML={{ __html: this.props.message }}
          />
        )}
        {this.props.status === "success" && (
          <div
            className="newsletter-form__message newsletter-form__message--success"
            dangerouslySetInnerHTML={{ __html: this.props.message }}
          />
        )}
      </div>
    );
  }
}

class NewsletterForm extends React.Component {
  render() {
    const url =
      "https://privacywave.us20.list-manage.com/subscribe/post?u=8629e375abfe668c4fda96e41&amp;id=52da0ef000";
    return (
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
          />
        )}
      />
    );
  }
}

export default NewsletterForm;
