import React from "react";

export default class MemberhshipHeader extends React.Component {
  state = {
    slide: 1
  };
  render() {
    // const width = 100 / this.props.totalSlides;
    const lineStyle = {
      width: "100%"
      // width: width + "%",
      // left: (this.state.slide - 1) * width + "%"
    };
    return (
      <ul
        className={`membership-header membership-header-active-${
          this.state.slide
        }`}
      >
        <li>
          <button
            onClick={e => {
              this.setState(() => ({ slide: 1 }));
              this.props.onClick(1);
            }}
          >
            Platinum
          </button>
        </li>
        {/* <li>
          <button
            onClick={e => {
              this.setState(() => ({ slide: 2 }));
              this.props.onClick(2);
            }}
          >
            Gold
          </button>
        </li>
        <li>
          <button
            onClick={e => {
              this.setState(() => ({ slide: 3 }));
              this.props.onClick(3);
            }}
          >
            Diamond
          </button>
        </li> */}
        <div className="membership-line" style={lineStyle} />
      </ul>
    );
  }
}
