import React from "react";

const BtnBlueStroke = props => {
  const { label, ...other } = props;
  if (props.href === undefined)
    return (
      <button className="btn btn--blue-stroke" {...other}>
        {label}
      </button>
    );
  else
    return (
      <a className="btn btn--blue-stroke" {...other}>
        {label}
      </a>
    );
};

export default BtnBlueStroke;
