import React from "react";
import SectionTitle from "../SectionTitle";

const API = "https://privacywave.com/wp/wp-json/wp/v2/reference";

export default class References extends React.Component {
  state = {
    data: []
  };

  componentDidMount() {
    fetch(API)
      .then(response => response.json())
      .then(data =>
        this.setState({
          data: data
        })
      );
  }

  render() {
    return (
      <section id="home-references">
        <div className="container">
          <SectionTitle h2="As seen on" line={false} />
          <ul className="reference-items">
            {this.state.data.map(reference => {
              return (
                <li key={reference.id}>
                  <a
                    href={reference.acf.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={reference.acf.logo.sizes.reference}
                      alt={reference.acf.label}
                    />
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    );
  }
}
