import React from "react";

export default class ContactOption extends React.Component {
  render() {
    return (
      <li onClick={this.props.onPick}>
        <div className="contact-option__icon">
          <img src={this.props.icon} alt="Contact Icon" />
        </div>
        <div className="contact-option__content">
          <h5>{this.props.label}</h5>
          <p>{this.props.text}</p>
        </div>
      </li>
    );
  }
}
