import React from "react";

const NavItem = props => {
  const { url, label, ...other } = props;
  return (
    <li>
      <a {...other} href={"/" + url}>
        {label}
      </a>
    </li>
  );
};

export default NavItem;
