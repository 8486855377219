import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";
import Header from "../common/sections/home/Header";
import Mission from "../common/sections/home/Mission";
import Reviews from "../common/sections/home/Reviews";
import Education from "../common/sections/home/Education";
import Instructor from "../common/sections/home/Instructor";
import Membership from "../common/sections/home/Membership";
import Blog from "../common/sections/home/Blog";
import References from "../common/sections/home/References";
import Contact from "../common/sections/home/Contact";
import { Helmet } from "react-helmet";

class HomePage extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>PrivacyWave | OSINT & Privacy Services by Ben Allen</title>
          <meta
            name="description"
            content="We help people every day escape online bullying, cyber stalking, harassment and protect their privacy by our result driven education programs, consulting, investigations and actionable intelligence."
          />
          <meta
            name="keywords"
            content="privacy guard,privacy by design,privacy risk assessment,delete personal data,harrassment,identity theft,identity compromised,privacy consulting,privacy counsel,privacypolicy,delete yourself,delete yourself from the internet,right to privacy,privacy meaning,gdpr,dangers of big data,privacy consulting ervices,deleteme,erase digital footprint,cyber bullying,cyber harassment,cyber stalking,internet privacy,information privacy,invisibility,identity theft investigation"
          />
          <link rel="canonical" href="https://privacywave.com" />
        </Helmet>
        <Header />
        <ScrollableAnchor id={"mission"}>
          <Mission />
        </ScrollableAnchor>
        <ScrollableAnchor id={"reviews"}>
          <Reviews />
        </ScrollableAnchor>
        <ScrollableAnchor id={"education"}>
          <Education />
        </ScrollableAnchor>
        <ScrollableAnchor id={"instructor"}>
          <Instructor />
        </ScrollableAnchor>
        <ScrollableAnchor id={"membership"}>
          <Membership />
        </ScrollableAnchor>
        <ScrollableAnchor id={"blog"}>
          <Blog />
        </ScrollableAnchor>
        <References />
        <ScrollableAnchor id={"contact"}>
          <Contact />
        </ScrollableAnchor>
      </div>
    );
  }
}

export default HomePage;
